import React from "react"
import styled from "styled-components"
import { Container, Headline1 } from "./defaultComponents"

const Wrap = styled(Container)`
  text-align: center;
  @media screen and (min-width: 768px) {
    margin: 0 auto;
    padding: 7vw 0 0;
    max-width: 850px;
  }
`

const Leistungen = () => {
  return (
    <Wrap id="leistungen">
      <Headline1>Leistungen</Headline1>
    </Wrap>
  )
}

export default Leistungen
