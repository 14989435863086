import React from "react"
import styled from "styled-components"
import { Wrap, Container, Text, Headline3 } from "./defaultComponents"
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa"

const Content = styled(Wrap)`
  background: var(--secondary-color);
  font-size: 1.2rem;
  margin: 4vw 0;
`

const ContentContainer = styled(Container)`
  color: white;
  display: grid;
  grid-template-areas: "a a" "b c";
  padding: 4vw 0;
  justify-content: center;
`

const Headline = styled(Headline3)`
  grid-area: a;
  text-align: center;
  margin-bottom: 2vw;
`

const LeftContainer = styled.div`
  grid-area: b;
  display: flex;
  text-align: center;
  margin-right: 4vw;
`

const RightContainer = styled.div`
  grid-area: c;
`

const CtaLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  color: white;

  & span {
    text-align: center;
  }

  & > svg {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    color: white;
    padding: 10px;
  }
`

const Cta = () => {
  return (
    <Content>
      <ContentContainer>
        <Headline>Kontakt aufnehmen</Headline>

        <LeftContainer>
          <CtaLink href="tel:015170803338">
            <FaPhoneAlt /> <br />
            <Text>0151 70803338</Text>
          </CtaLink>
        </LeftContainer>
        <RightContainer>
          <CtaLink href="mailto:wiebke.klingsporn@gmx.de">
            <FaEnvelope /> <br />
            <Text>wiebke.klingsporn@gmx.de</Text>
          </CtaLink>
        </RightContainer>
      </ContentContainer>
    </Content>
  )
}

export default Cta
