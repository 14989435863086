import * as React from "react"
import Hero from "../components/1_Hero"
import Motivation from "../components/2_Motivation"
import About from "../components/3_About"
import Leistungen from "../components/4_Leistungen"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Branchen from "../components/5_Branchen"
import Cta from "../components/7_Cta"
import Video from "../components/6_Video"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Hero />
    <Motivation />
    <About />
    <Leistungen />
    <Branchen />
    <Video />
    <Cta />
  </Layout>
)

export default IndexPage
