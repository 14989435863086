import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { Wrap, ButtonExternal } from "./defaultComponents"
import { FaLongArrowAltDown } from "react-icons/fa"
import scrollTo from "gatsby-plugin-smoothscroll"

const Wrapper = styled(Wrap)`
  display: grid;
  grid-template-areas: "a";

  @media screen and (min-width: 1080px) {
    height: 100vh;
    width: 100vw;
    max-height: 1200px;
  }
`

const Background = styled.div`
  grid-area: a;
  width: 100%;
  height: 100%;

  & > div {
    width: 100%;
    height: 100%;
  }
`

const Foreground = styled.div`
  grid-area: a;
  padding: 10vw;
  max-width: 60vw;
  align-self: left;

  @media screen and (min-width: 768px) {
    padding: 0;
    width: 400px;
    max-width: 90%;
    margin-left: 5vw;
    align-self: center;
    margin-right: auto;
    text-align: center;
    z-index: 2;

    & > div {
      margin-bottom: 30px;
    }
  }
`

const Hero = () => {
  return (
    <Wrapper id="start">
      <Background>
        <StaticImage
          src="../images/01_head.jpg"
          alt="Wiebke Klingsporn Portrait"
          objectFit="cover"
          objectPosition="center right"
          loading="eager"
          placeholder="blurred"
        />
      </Background>
      <Foreground>
        <StaticImage
          src="../images/logo.png"
          alt="Wiebke Klingsporn Gebärdensprachdolmetscherin Logo"
          width={500}
        />
        <ButtonExternal onClick={() => scrollTo("#motivation")}>
          Mehr erfahren <FaLongArrowAltDown />
        </ButtonExternal>
      </Foreground>
    </Wrapper>
  )
}

export default Hero
