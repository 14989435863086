import React from "react"
import styled from "styled-components"
import { Container, Headline1, Text } from "./defaultComponents"

const Wrap = styled(Container)`
  margin: 0 auto;
  padding: 7vw 0;
  @media screen and (min-width: 768px) {
    text-align: center;
    max-width: 850px;
  }
`

const Subline = styled.h3`
  font-weight: bold;
`

const Motivation = () => {
  return (
    <Wrap id="motivation">
      <Headline1>Meine Motivation</Headline1>
      <Subline>Einander verstehen, erfordert Kommunikation.</Subline>
      <Text>
        Ich möchte, dass taube und hörende Menschen flexibel und barrierefrei
        miteinander kommunizieren können, ohne Missverständnisse, Hindernisse
        und Frust. Durch meine Dolmetschdienstleistung trage ich dazu bei. An
        erster Stelle steht für mich, dass du als Kund:in mit einem sicheren und
        guten Gefühl in und aus Gesprächssituationen gehst. Ganz klar, lege ich
        größten Wert auf eine fehlerfreie Verdolmetschung, Transparenz,
        Neutralität und Verschwiegenheit.
      </Text>
    </Wrap>
  )
}

export default Motivation
