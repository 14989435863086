import React from "react"
import styled from "styled-components"
import { Container, Headline3, Text } from "./defaultComponents"
import { StaticImage } from "gatsby-plugin-image"

const Wrap = styled(Container)`
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 5vw;
    row-gap: 5vw;
  }
`

const BranchenItem = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;

  & > div {
  }
`

const Icon = styled.div`
  display: grid;
  grid-template-areas: "a";
  box-sizing: border-box;
  max-width: 200px;
  margin: 0 auto 20px;

  & > * {
    grid-area: a;
  }
`

const Branchen = () => {
  return (
    <Wrap>
      <BranchenItem>
        <Icon>
          <StaticImage
            src="../images/icons/kreis.png"
            alt=""
            width={150}
            loading="lazy"
            placeholder="tracedSVG"
          />
          <StaticImage
            src="../images/icons/arbeitsleben.svg"
            alt=""
            width={100}
            loading="lazy"
            placeholder="tracedSVG"
          />
        </Icon>
        <Headline3>Arbeitsleben</Headline3>
        <Text>
          Teamsitzungen, Betriebsversammlungen, Fortbildungen,
          Vorstellungsgespräche
        </Text>
      </BranchenItem>

      <BranchenItem>
        <Icon>
          <StaticImage
            src="../images/icons/kreis.png"
            alt=""
            width={150}
            loading="lazy"
            placeholder="tracedSVG"
          />
          <StaticImage
            src="../images/icons/bildung.svg"
            alt=""
            width={100}
            loading="lazy"
            placeholder="tracedSVG"
          />
        </Icon>
        <Headline3>Bildung</Headline3>
        <Text>
          inklusive Beschulung, Studium, Ausbildung, Elternabende,
          Elternsprechtage
        </Text>
      </BranchenItem>

      <BranchenItem>
        <Icon>
          <StaticImage
            src="../images/icons/kreis.png"
            alt=""
            width={150}
            loading="lazy"
            placeholder="tracedSVG"
          />
          <StaticImage
            src="../images/icons/gesundheitswesen.svg"
            alt=""
            width={100}
            loading="lazy"
            placeholder="tracedSVG"
          />
        </Icon>
        <Headline3>Gesundheitswesen</Headline3>
        <Text>
          Arztbesuche, Therapien, Krankenhausbesuche, Rehabilitation,
          Geburtsvorbereitung
        </Text>
      </BranchenItem>

      <BranchenItem>
        <Icon>
          <StaticImage
            src="../images/icons/kreis.png"
            alt=""
            width={150}
            loading="lazy"
            placeholder="tracedSVG"
          />
          <StaticImage
            src="../images/icons/kultur.svg"
            alt=""
            width={100}
            loading="lazy"
            placeholder="tracedSVG"
          />
        </Icon>
        <Headline3>Kultur & Religion</Headline3>
        <Text>
          Gottesdienste, Museum, Theatervorstellungen, Messen, Veranstaltungen
        </Text>
      </BranchenItem>

      <BranchenItem>
        <Icon>
          <StaticImage
            src="../images/icons/kreis.png"
            alt=""
            width={150}
            loading="lazy"
            placeholder="tracedSVG"
          />
          <StaticImage
            src="../images/icons/privatleben.svg"
            alt=""
            width={100}
            loading="lazy"
            placeholder="tracedSVG"
          />
        </Icon>
        <Headline3>Privatleben</Headline3>
        <Text>
          Hochzeiten, Geburtstage, Wohnungsbesichtigungen, Haus- und Autokauf
        </Text>
      </BranchenItem>

      <BranchenItem>
        <Icon>
          <StaticImage
            src="../images/icons/kreis.png"
            alt=""
            width={150}
            loading="lazy"
            placeholder="tracedSVG"
          />
          <StaticImage
            src="../images/icons/verwaltung.svg"
            alt=""
            width={100}
            loading="lazy"
            placeholder="tracedSVG"
          />
        </Icon>
        <Headline3>Verwaltung & Behörde</Headline3>
        <Text>Agentur für Arbeit, Jobcenter, Jugendamt, Polizei</Text>
      </BranchenItem>
    </Wrap>
  )
}

export default Branchen
