import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"

const Wrap = styled.header`
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
    width: 100%;
    position: fixed;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.3);
    z-index: 99;
    background: white;
  }
`

const Container = styled.div`
  @media screen and (min-width: 768px) {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    padding: 20px;
  }
`

const LogoBox = styled.div`
  display: grid;
  place-items: center;
  @media screen and (min-width: 768px) {
    cursor: pointer;
    flex-basis: 220px;
    max-width: 20%;

    & > div {
      width: 100%;
    }
  }
`

const Navigation = styled.nav`
  flex-basis: 80%;
`

const NavItem = styled.li`
  @media screen and (min-width: 768px) {
    list-style-type: none;
    text-transform: uppercase;
  }
`

const Nav = styled.ul`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    & > ${NavItem}  {
      margin-left: 15px;
    }
  }
`

const NavLink = styled.a`
  cursor: pointer;
  transition: 0.2s ease color;

  &:hover {
    color: var(--primary-color);
  }

  &:hover svg {
    fill: var(--primary-color);
  }
`

const Navbar = () => {
  return (
    <Wrap>
      <Container>
        <LogoBox onClick={() => scrollTo("#start")}>
          <StaticImage
            src="../images/wiebke_klingsporn.svg"
            alt="Wiebke Klingsporn"
            loading="eager"
            placeholder="tracedSVG"
          />
        </LogoBox>
        <Navigation>
          <Nav>
            <NavItem>
              <NavLink onClick={() => scrollTo("#start")}>Home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={() => scrollTo("#motivation")}>
                Motivation
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={() => scrollTo("#about")}>Über mich</NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={() => scrollTo("#leistungen")}>
                Leistungen
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={() => scrollTo("#DGS")}>
                DGS{" "}
                <StaticImage
                  src="../images/sign-language.svg"
                  alt="Wiebke Klingsporn"
                  width={15}
                />
              </NavLink>
            </NavItem>
          </Nav>
        </Navigation>
      </Container>
    </Wrap>
  )
}

export default Navbar
