import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { Container, Headline1, Text } from "./defaultComponents"

const Wrap = styled(Container)`
  & > div:first-child {
    max-height: 650px;
  }
  @media screen and (max-width: 768px) {
    & > div:first-child {
      max-height: 450px;
      width: 100%;
    }
  }
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5vw;
    align-items: center;

    position: relative;

    & > div:first-child {
      position: relative;
      z-index: 2;
      overflow: visible;
    }

    & > div:first-child:before {
      content: "";
      position: absolute;
      top: -10px;
      left: -10px;
      width: 4vw;
      height: 3vw;
      border-top: 3px solid var(--primary-color);
      border-left: 3px solid var(--primary-color);
      transform: translate(-5px, -5px);
      z-index: 5;
    }

    & > div:first-child:after {
      content: "";
      position: absolute;
      bottom: -10px;
      right: -10px;
      width: 4vw;
      height: 3vw;
      border-bottom: 3px solid var(--primary-color);
      border-right: 3px solid var(--primary-color);
      transform: translate(5px, 5px);
      z-index: 5;
    }
  }
`

const Content = styled.div`
  margin: 7vw auto;
`

const Wasserzeichen = styled.div`
  display: none;

  @media screen and (min-width: 768px) {
    position: absolute;
    top: -13vw;
    left: 65%;
    width: 50vw;
    z-index: -1;
    transform: translateX(-50%);
  }
`

const About = () => {
  return (
    <Wrap id="about">
      <StaticImage
        src="../images/02_about_img.jpg"
        alt=""
        objectFit="cover"
        loading="lazy"
        placeholder="blurred"
      />
      <Content>
        <Wasserzeichen>
          <StaticImage
            src="../images/wasserzeichen_wk.png"
            alt=""
            loading="lazy"
            placeholder="blurred"
          />
        </Wasserzeichen>
        <Headline1>Über mich</Headline1>
        <Text>
          Hey, ich bin Wiebke und freue mich sehr, dass du da bist!
          <br />
          Vielleicht verrät dir schon mein Vorname, dass ich ursprünglich ein
          Nordlicht bin. Mein Traumberuf Gebärdensprachdolmetscherin hat mich
          aber ins schöne Köln verschlagen. Hier lebe und arbeite ich seit dem
          Jahr 2015. Die Geburt meiner Tochter und das damit verbundene
          Abenteuer habe ich zum Anlass genommen, mich 2021 selbstständig zu
          machen. In den letzten Jahren habe ich beruflich zahlreiche und
          vielseitige Erfahrungen sammeln können und bin daran gewachsen. Dieser
          Prozess soll weitergehen, daher freue ich mich unheimlich auf deinen
          Auftrag!
        </Text>
      </Content>
    </Wrap>
  )
}

export default About
