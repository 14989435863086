import React from "react"
import styled from "styled-components"
import { Container } from "./defaultComponents"
import Movie from "../images/movie.mp4"

const Wrap = styled(Container)`
  margin: 7vw auto;
  @media screen and (min-width: 768px) {
    position: relative;
    z-index: 2;
    padding: 10px;
    overflow: visible;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 4vw;
      height: 3vw;
      border-top: 3px solid var(--primary-color);
      border-left: 3px solid var(--primary-color);
      transform: translate(-5px, -5px);
      z-index: 5;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 4vw;
      height: 3vw;
      border-bottom: 3px solid var(--primary-color);
      border-right: 3px solid var(--primary-color);
      transform: translate(5px, 5px);
      z-index: 5;
    }
  }
`

const VideoPlayer = styled.video`
  width: 100%;
`

const Video = () => {
  return (
    <Wrap id="DGS">
      <VideoPlayer controls>
        <source src={Movie} type="video/mp4" />
        Your browser does not support the video tag.
      </VideoPlayer>
    </Wrap>
  )
}

export default Video
